import React from 'react';
import get from 'lodash/get';

// Components
import { MarkdownTemplate } from 'templates';

// Layouts
import { SEOContent } from 'layouts';

// Hooks
import { useJoinUsPageSectionsQuery } from 'hooks';

// Styles
import {
  SubscribeFormSection,
  SubscribeFormTitle,
} from 'components/Subscribe/local-styles';
import { NoImage, PostFull } from 'styles';

const JoinUsPageTemplate = () => {
  const { sections } = useJoinUsPageSectionsQuery();
  // If we embed a form, use this.
  const formUrl = get(sections, '[0].data.form_url') || '';

  return (
    <SEOContent title="Join the MSTCA">
      <MarkdownTemplate postTitle="Join Us" sections={sections}>
        <section css={[PostFull, NoImage]}>
          <SubscribeFormSection>
            <h3 css={SubscribeFormTitle}>
              <a
                href={formUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Join the MSTCA
              </a>
            </h3>
          </SubscribeFormSection>
        </section>
      </MarkdownTemplate>
    </SEOContent>
  );
};

export default JoinUsPageTemplate;
